import { Component, Input  } from '@angular/core';
import { icons } from './icons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'Icon',
  standalone: true,
  templateUrl: './icon.component.html',
  imports:[CommonModule]
})
export class IconComponent  {
  @Input({ required: true }) src!: string;
  @Input() class!: string;
}
